<template>
    <div class="sup_content sup_coupon">
        <!--- Create New Role --->
        <div class="sup_page_heading">
            <h2>Cookie Management</h2>
        </div>
        <div class="sup_inner_content">
            <!--- Show Language list Start --->
            <DataTable class="p-datatable" :paginator="true" :value="allCookieData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allCookieData.loading" responsiveLayout="scroll" removableSort :filters="filters">
                <template #loading>
                    Loading Cookie data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">
                            <Button :label="allCookieData.activityStatus === 'active' ? 'Inactive List': 'Active List'" :icon="['pi', allCookieData.activityStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allCookieData.activityStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllCookie(allCookieData.activityStatus, 'languageStatus')" />
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allCookieData.search" placeholder="Search Cookie" @keyup.enter="fetchAllCookie(allCookieData.activityStatus, 'search')"/>
                            </span>
                            <Calendar v-model="allCookieData.dateRange" placeholder="DD.MM.YY" dateFormat="dd.mm.yy" selectionMode="range" class="sup_button p-ml-2" inputClass="p-inputtext" :manualInput="false" :showIcon="true" @date-select="allCookieData.dateRange[1] != null ? fetchAllCookie(allCookieData.activityStatus, 'dateRange') : ''" plceholder="abc" />
                            <Button v-if="allCookieData.search !== null || allCookieData.dateRange !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllCookie(allCookieData.activityStatus, 'resetFilters')"/>
                            <SplitButton label="Export" icon="pi pi-external-link" class="p-button-secondary p-ml-2" :model="exportType"></SplitButton>
                            <Button icon="pi pi-plus" class="p-button-primary p-ml-2" label="Import" @select="fileSelected($event)" @click="openCookieForm = true"/>
                            <Button class="p-button-secondary p-ml-2" label="Classify Cookies" @click="classifyCookie()"/>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No Cookies found...
                </template>

                <Column field="marked" header="Marked">
                <template #body="slotProps">
                    <span>
                        <input v-if="allCookieData.markedItems.includes(slotProps.data.uuid)" type="checkbox" id="check" name="t" @change="markUnmark(slotProps)" checked>
                        <input v-else type="checkbox" id="check" name="t" @change="markUnmark(slotProps)" >
                    </span>
                </template>
                </Column>

                <Column field="name" header="Name" :sortable="true" />

                <Column field="category" header="Category" sortable filterField="Cookiecategorie" sortField="Cookiecategorie.categoryName">
                    <template #body="slotProps">
                        {{slotProps.data.Cookiecategorie.categoryName}}
                    </template>
                </Column>

                <Column field="provider" header="Provider" :sortable="true"/>

                <Column field="expiry" header="Expiry" :sortable="true"/>

                <Column field="domain" header="Domain" :sortable="true"/>

                <Column field="status" header="Status" headerStyle="8rem" sortable filterField="data" sortField="data.status">
                    <template #body="slotProps">
                        <span :class="['sup_item_status ', slotProps.data.status ? 'sup_item_status_success' : 'sup_item_status_danger']"><i :class="['pi ', slotProps.data.status ? 'pi-check' : 'pi-times']"></i>{{slotProps.data.status ? 'Active' : 'In-Active'}}</span>
                    </template>
                </Column>

                <Column field="action" header="Actions">
                    <template #body="slotProps">
                        <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-primary p-mr-1" @click="editCookie(slotProps.data)" v-tooltip="'Edit Cookie'" />
                        <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-ban" class="p-button-sm p-button-rounded p-button-danger p-mr-1" v-tooltip="'Deactivate'" @click="confirmationModalfn(slotProps.data, 'delete')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-undo" class="p-button-secondary p-button-rounded p-button-sm p-mr-1"  v-tooltip="'Restore Cookie?'" @click="confirmationModalfn(slotProps.data, 'restore')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-trash" class="p-button-danger p-button-rounded p-button-sm"  v-tooltip="'Delete Cookie?'" />
                    </template>
                </Column>
            </DataTable>
            <!--- Show Language list End --->
            <!--- Edit Cookie Modal Start --->
            <Dialog header="Edit Cookie" v-model:visible="cookieModals.editCookie" :modal="true" :style="{width: '600px'}" @hide="closeModal()">
            <form class="sup_coupon_edit" @submit.prevent="updateCookie(cookieModals.editData.uuid)">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="cookie_name">Cookie Name</label>
                        <InputText v-model="addEditCookie.name"  id="cookie_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="editCookieError.nameEmpty">Cookie Name is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="cookie_provider">Cookie Provider</label>
                        <InputText v-model="addEditCookie.provider"  id="cookie_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="editCookieError.providerEmpty">Cookie Provider is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="cookie_purpose">Purpose</label>
                        <InputText v-model="addEditCookie.purpose"  id="cookie_purpose" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="editCookieError.purposeEmpty">Cookie Purpose is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="cookie_expiry">Expiry</label>
                        <InputText v-model="addEditCookie.expiry"  id="cookie_expiry" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="editCookieError.expiryEmpty">Expiry is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="cookie_type">Type</label>
                        <InputText v-model="addEditCookie.type"  id="cookie_type" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="editCookieError.typeEmpty">Cookie Type is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="cookie_domain">Domain</label>
                        <InputText v-model="addEditCookie.domain"  id="cookie_expiry" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="editCookieError.domainEmpty">Domain is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="cookie_category">Category</label>
                        <Dropdown :v-for="categoryList.data" v-model="addEditCookie.cookieCategorieId" :options="categoryList.data" id="cookie_category" optionLabel="categoryName" optionValue="id" v-bind:selected="id ===addEditCookie.cookieCategorieId"/>
                        <small class="p-invalid sup_text_danger" v-if="editCookieError.cookieCategorieIdEmpty">Category is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Update" class="btn-primary sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', allCookieData.editStatus === 200 ? 'success' : 'error']" v-if="allCookieData.editStatus !== ''">{{allCookieData.editMessage}}</div >
            </form>
            </Dialog>
            <!--- Edit Cookie Modal End --->
            <!--- Classify Cookie Modal Start --->
            <Dialog header="Classify Cookie" v-model:visible="cookieModals.classifyCookie" :modal="true" :style="{width: '600px'}" @hide="closeModal()">
            <form class="sup_coupon_edit" @submit.prevent = "updateCookieClassification(allCookieData.classifyData)">
                
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="cookie_category">Pick a Category to assign</label>
                        <Dropdown :v-for="categoryList.data" v-model="allCookieData.classifyData" :options="categoryList.data" id="cookie_category" optionLabel="categoryName" optionValue="uuid" placeholder="Choose a Category"/>
                        <small class="p-invalid sup_text_danger" v-if="editCookieError.cookieCategorieIdEmpty">Category is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Assign Category" class="btn-primary sup_submit"/>
                </div>
                <div :class="['sup_form_massage p-mt-3', allCookieData.classifyStatus === 200 ? 'success' : 'error']" v-if="allCookieData.classifyStatus !== ''">{{allCookieData.classifyMessage}}</div >
            </form>
            </Dialog>
            <!--- Classify Cookie Modal End --->
            <!--- Add Media Modal Start --->
            <Dialog header="Import Cookies" v-model:visible="openCookieForm" :modal="true" :style="{width: '600px'}">
                <FileUpload name="demo[]" :multiple="false" chooseLabel="Browse" :customUpload="true" @uploader="importXL($event)" />
                <a href="https://console.truabilities.com/assets/Import-Cookie-Sample%20Data.csv" download="csv">
                    <Button label="Download Sample" class="p-button-outlined" />
                </a>
            </Dialog>
            <!--- Add Media Modal End --->
        </div>
        <!--- Deactive/Restore Confirmation Start --->
        <Dialog header="Confirmation" v-model:visible="confirmationModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="confirmationModal.modalState = false" class="p-button-text" autofocus/>
                <Button label="Yes" icon="pi pi-check" @click="deleteAndRestoreCookie(confirmationModal.modaldata.data, confirmationModal.modaldata.load), confirmationModal.modalState = false" class="p-button-primary" />
            </template>
        </Dialog>
        <!--- Deactive/Restore Confirmation End --->
        <!--- Warning Modal Start --->
        <Dialog header="Warning" v-model:visible="warningModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Please choose Cookies to classify first!</span>
            </div>
            <template #footer>
                <Button label="Ok" icon="pi pi-check" @click="warningModal.modalState=false" class="p-button-primary" />
            </template>
        </Dialog>
        <!--- Warning Modal End --->
    </div>
</template>

<script>
import { onBeforeMount, reactive, ref } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { allCookieCategory, allCookieList, cookieExport, cookieImport } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import { formatDate } from "../../../../helper/utility/utility";
const FileSaver = require('file-saver');
// , usFormatDate, capitalizeCase

export default {
    name: "CookieManagement",
    setup(){
        //Store call
        let store = useStore();

        //Fetch and filtring data initial state
        const allCookieData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'all',
            print: 'false',
            search: null,
            dateRange: null,
            addStatus: '',
            addMessage: '',
            editStatus: '',
            editMessage: '',
            classifyData: '',
            classifyMessage: '',
            classifyStatus: '',
            markedItems: []
        });

        //Component Profile local data
        const cookieState = reactive({
            loading: false,
            newUrlSelected: false,
            selectedUrl: ''
        });

        //Profile details form managing state
        const cookieDetails = reactive({
            file: null
        });

        const openCookieForm = ref(false);
        
        //Export type and export event handler
        const exportType = reactive([
            {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                command: () => {
                    exportXL('excel');
                }
            },
            {
                label: 'PDF',
                icon: 'pi pi-file-pdf',
                command: () => {
                    exportXL('pdf');
                }
            }
        ]);

         /**
         * File after upload store to local state
         */
        const fileSelected = (e) => {
            cookieDetails.file = e.files[0];
            console.log(cookieDetails.file);
            cookieState.newUrlSelected = true;
            cookieState.selectedUrl = e.files[0].objectURL;
            console.log(cookieState.selectedUrl);
        }

        //Before component mount required function invoke
        onBeforeMount(async () => {
            fetchAllCookie('active');
        });

        const fetchAllCookie = async(status, activity) => {
            let date = null;
            if(activity === 'languageStatus'){
                allCookieData.activityStatus = status === 'active' ? 'de-active' : 'active' ;
            }

            if(activity === 'resetFilters'){
                allCookieData.search = null;
                allCookieData.dateRange = null;
            }

            if (activity === 'dateRange') {
                date = formatDate(allCookieData.dateRange[0]) + ' ' + formatDate(allCookieData.dateRange[1]);
            }

            try{
                allCookieData.loading = !allCookieData.loading;
                const response = await axios.get(allCookieList, {
                    params: {
                        status: allCookieData.activityStatus,
                        search: allCookieData.search,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                })
                allCookieData.data = response.data.data.cookies;
                allCookieData.total = response.data.data.total;
                allCookieData.loading = !allCookieData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        //Export Website List
        const exportXL = async(load) => {
            let date = null;
            if (load === 'excel') {
                try{
                    // allUserData.limit = "";
                    // allUserData.page = "";
                    // + '&page=' + allUserData.page + '&limit=' + allUserData.limit + '&sortBy=' + allUserData.sortBy + '&sortOrder='+ allUserData.sortOrder
                    //const queryUrl = userExport + '?status=' + allUserData.status + '&searchData' + allUserData.searchUser ;
                    if (allCookieData.dateRange !== null) {
                        date = formatDate(allCookieData.dateRange[0]) + ' ' + formatDate(allCookieData.dateRange[1]);
                    }
                    const response = await axios.get( cookieExport, {
                        responseType: 'arraybuffer',
                        params: {
                            print : allCookieData.print,
                            search: allCookieData.search,
                            filterDt: date,
                            exe: load
                        },headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if(response.status === 200) {
                        const dirtyFileName = response.headers['content-disposition'];
                        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                        var fileName = dirtyFileName.match(regex)[3];
                        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        FileSaver(blob, fileName);
                    }
                } catch(err) {
                    console.log('error', err);
                }   
            }
            if (load === 'pdf') {
                try{
                    if (allCookieData.dateRange !== null) {
                        date = formatDate(allCookieData.dateRange[0]) + ' ' + formatDate(allCookieData.dateRange[1]);
                    }
                    const response = await axios.get( cookieExport, {
                        responseType: 'arraybuffer',
                        params: {
                            print : allCookieData.print,
                            search: allCookieData.search,
                            filterDt: date,
                            exe: load
                        },headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if(response.status === 200) {
                        const dirtyFileName = response.headers['content-disposition'];
                        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                        var cFileName = dirtyFileName.match(regex)[3];
                        var cBlob = new Blob([response.data], { type: 'application/pdf' });
                        FileSaver(cBlob, cFileName);
                    }
                } catch(err) {
                    console.log('error', err);
                }   
            }
        }

        const importXL = async(event) => {   
            console.log(event.files);
            try{
                let formData = new FormData();
                formData.append('import', event.files[0]);
                console.log(formData);
                const options = {
                    method: 'post',
                    url: cookieImport,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data", 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                const res = await axios(options);
                if(res.data.status === 200){
                    setTimeout(()=>{
                        openCookieForm.value = false;
                        fetchAllCookie('active')
                    },1000);
                }
            } catch(err){
                console.error(err);
            }
        }

        // Edit Cookie
        const editCookie = async(slotData) => {
            cookieModals.editCookie = true;
            cookieModals.editData = slotData;
            addEditCookie.name = slotData.name;
            addEditCookie.provider = slotData.provider;
            addEditCookie.purpose = slotData.purpose;
            addEditCookie.expiry = slotData.expiry;
            addEditCookie.type = slotData.type;
            addEditCookie.domain = slotData.domain;
            addEditCookie.cookieCategorieId = slotData.cookieCategorieId;
            getCategoryList();
        }

        // Edit coupon form validation state
        const editCookieError = reactive({
            nameEmpty: false,
            providerEmpty: false,
            purposeEmpty: false,
            expiryEmpty: false,
            typeEmpty: false,
            domainEmpty: false
        });

        //Cookie modals handling state
        const cookieModals = reactive({
            addCookie: false,
            editCookie: false,
            editData: false,
            classifyCookie: false,
            classifyCookieData: false
        });

        // Add/Edit Cookie Input data Store
        const addEditCookie = reactive({
            name: '',
            provider: '',
            purpose: '',
            expiry: '',
            type: '',
            domain: ''
        });
        // Update existing Cookie
        const updateCookie = async(uuid) => {
            
            let validation = editCookieValidationForm();
            
            if(validation) {
                try{
                    const options = {
                        method: 'put',
                        url: allCookieList,
                        params: {
                            uuid: uuid
                        },
                        data: JSON.stringify({
                            name: addEditCookie.name,
                            provider: addEditCookie.provider,
                            purpose: addEditCookie.purpose,
                            expiry: addEditCookie.expiry,
                            type: addEditCookie.type,
                            domain: addEditCookie.domain,
                            cookieCategorieId: addEditCookie.cookieCategorieId
                        }),
                        headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                        transformRequest: [(data) => {
                            // transform the data
                            return data;
                        }]
                    };
                    let response = await axios(options);
                    
                    allCookieData.editStatus = response.data.status;
                    allCookieData.editMessage = response.data.message;
                    fetchAllCookie('active');
                } catch(err){
                    allCookieData.editStatus = err.response.data.status;
                    allCookieData.editMessage = err.response.data.message;
                }
            }
        }

        //Cookie form validation
        const editCookieValidationForm = () => {
            if (addEditCookie.name === '') {
                editCookieError.nameEmpty = true;
            } else {
                editCookieError.nameEmpty = false;
            }

            if (addEditCookie.provider === '') {
                editCookieError.providerEmpty = true;
            } else {
                editCookieError.providerEmpty = false;
            }

            if (addEditCookie.purpose === '') {
                editCookieError.purposeEmpty = true;
            } else {
                editCookieError.purposeEmpty = false;
            }

            if (addEditCookie.expiry === '') {
                editCookieError.expiryEmpty = true;
            } else {
                editCookieError.expiryEmpty = false;
            }

            if (addEditCookie.type === '') {
                editCookieError.typeEmpty = true;
            } else {
                editCookieError.typeEmpty = false;
            }

            if (addEditCookie.domain === '') {
                editCookieError.domainEmpty = true;
            } else {
                editCookieError.domainEmpty = false;
            }

            if (addEditCookie.cookieCategorieId === '') {
                editCookieError.cookieCategorieIdEmpty = true;
            } else {
                editCookieError.cookieCategorieIdEmpty = false;
            }

            if(!editCookieError.nameEmpty && !editCookieError.providerEmpty && !editCookieError.purposeEmpty && !editCookieError.expiryEmpty && !editCookieError.typeEmpty && !editCookieError.domainEmpty && !editCookieError.cookieCategorieIdEmpty)
                return true;
            else
                return false;
        }

        const closeModal = () => {            
            allCookieData.addStatus = '';
            allCookieData.addMessage = '';
            allCookieData.editStatus = '';
            allCookieData.editMessage = '';
            allCookieData.classifyMessage = '';
            allCookieData.classifyStatus = '';
            allCookieData.markedItems = [];
        }


        let categoryList = reactive({data: []});
        const getCategoryList = async function() {
            try {
                const response = await axios.get(allCookieCategory, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                categoryList.data = response.data.data.cookieCategorie;

            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }
                
        let assigningCategory = '';
        // Classify Cookie
        const classifyCookie = async() => {
            if(allCookieData.markedItems.length === 0) {
                warningModal.modalState = true;
                closeModal();
                return;
            }
            cookieModals.classifyCookie = true;
            cookieModals.classifyCookieData = allCookieData.markedItems;
            
            getCategoryList();
        }

        const markUnmark = async function(slotProps) {
            if(!allCookieData.markedItems.includes(slotProps.data.uuid))
                allCookieData.markedItems.push(slotProps.data.uuid);
            else {
                allCookieData.markedItems = allCookieData.markedItems.filter(function (element) {
                    return element !== slotProps.data.uuid;
                })
            }
        }

        const updateCookieClassification = async function(uuid) {
            let validation = true//editCookieValidationForm();
            if(validation) {
                try{
                    const options = {
                        method: 'patch',
                        url: allCookieList + 'cookie-category',
                        data: JSON.stringify({
                            cookieCategoryId: uuid,
                            cookieIds: allCookieData.markedItems
                        }),
                        headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                        transformRequest: [(data) => {
                            // transform the data
                            return data;
                        }]
                    };
                    let response = await axios(options);
                    allCookieData.classifyStatus = response.data.status;
                    allCookieData.classifyMessage = response.data.message;
                    fetchAllCookie('active');
                } catch(err){
                    allCookieData.classifyStatus = err.response.data.status;
                    allCookieData.classifyMessage = err.response.data.message;
                }
            }
        }

        const confirmationModal = reactive({
            modalState: false,
            modaldata: ''
        })

        const confirmationModalfn = (sloteData, load) => {
            confirmationModal.modalState = true;
            confirmationModal.modaldata = {
                data: sloteData,
                load: load
            }
        }

        //Cookie active/deactivate mode function
        const deleteAndRestoreCookie = async (slotData, type) => {
            try{
                const options = {
                    method: type === 'delete' ? 'delete' : 'patch',
                    url: allCookieList,
                    params: {
                        uuid: slotData.uuid
                    },
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                await axios(options);
                fetchAllCookie('active');
            } catch(err){
                console.log(err);
            }
        }

        const warningModal = reactive({
            modalState: false
        })

        return{
            exportType,
            allCookieData,
            fetchAllCookie,
            exportXL,
            fileSelected,
            cookieState,
            cookieDetails,
            importXL,
            openCookieForm,
            editCookie,
            editCookieError,
            cookieModals,
            addEditCookie,
            editCookieValidationForm,
            updateCookie,
            categoryList,
            getCategoryList,
            closeModal,
            assigningCategory,
            classifyCookie,
            markUnmark,
            updateCookieClassification,
            confirmationModalfn,
            confirmationModal,
            deleteAndRestoreCookie,
            warningModal
        }
    }
}
</script>

<style>

</style>